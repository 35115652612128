"use client";

import { AiOutlineMenu } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { BiUserCircle } from "react-icons/bi";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/navigation";

import useLoginModal from "@/app/hooks/useLoginModal";
import useRegisterModal from "@/app/hooks/useRegisterModal";
import { SimplifiedUser } from "@/app/types";
import MenuItem from "./MenuItem";
import { useEffect, useState } from "react";

interface UserMenuProps {
  currentUser?: SimplifiedUser | null;
  isOpen: boolean;
  toggleOpen: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({
  currentUser,
  isOpen,
  toggleOpen,
}) => {
  const router = useRouter();
  const loginModal = useLoginModal();
  const registerModal = useRegisterModal();
  const { data: session } = useSession();
  const [localUserName, setLocalUserName] = useState(session?.user?.name);

  useEffect(() => {
    if (session?.user?.name && session.user.name !== undefined && session.user.name !== localUserName) {
      setLocalUserName(session.user.name);
      router.refresh();
    }
  }, [session?.user?.name]);
  
  const handleSignOut = () => {
    const callbackUrl = process.env.NEXTAUTH_URL;
    signOut({ callbackUrl });
    setLocalUserName(null);
  };

  const handleMenuItemClick = (action: () => void) => {
    action();
    toggleOpen();
  };

  const userData = {
    type: currentUser?.userType,
  };

  const createUrlWithParams = (path: string, data: object) =>
    `${path}?userData=${encodeURIComponent(JSON.stringify(data))}`;

  const menuItems = currentUser || session?.user
    ? [
        ...(currentUser?.userType === "Diver" || session?.user.userType === "Diver"
          ? [
              {
                label: "Edit profile",
                path: `/editprofile/${currentUser?.id}`,
                data: userData,
              },
              {
                label: "My reviews",
                path: `/myreviews/${currentUser?.id}`,
                data: userData,
              },
              { label: "My favorites", path: "/favorites", data: userData },
              // { label: "All job listings", path: "/job-listings" },
              {
                label: "Write a review",
                path: "/write-review",
                style: "bg-atoll-500",
              },
              ...(currentUser?.role === "ADMIN" || session?.user.role === "ADMIN"
                ? [
                    { label: "Claim center", path: "/adm/claim-center" },
                    {
                      label: "Centers management",
                      path: "/adm/center-management",
                    },
                    { label: "Approval centers", path: "/adm/approval-center" },
                  ]
                : []),
            ]
          : []),

        ...(currentUser?.userType === "Center" || session?.user.userType === "Center"
          ? [
              {
                label: "Edit profile",
                path: `/editprofile/${currentUser?.id}`,
                data: userData,
              },
              {
                label: "My profile",
                path: `/center/${currentUser?.diveCenter?.id}`,
                data: userData,
              },
              { label: "My job listings", path: "/my-job-listings" },
              {
                label: "List your jobs",
                path: "/register-job-listing",
                style: "bg-atoll-500",
              },
            ]
          : []),

        { label: "Logout", onClick: handleSignOut },
      ]
    : [
        { label: "Login", onClick: loginModal.onOpen },
        { label: "Sign up", onClick: registerModal.onOpen },
      ];

  return (
    <div className="relative">
      <div className="flex flex-row items-center gap-3">
        <div
          onClick={toggleOpen}
          className="
            p-2 md:py-2 md:px-3 border-[1px] 
            border-neutral-200 flex flex-row items-center 
            gap-3 rounded-lg cursor-pointer 
            hover:shadow-md transition
          "
        >
          {!currentUser ? (
            <BiUserCircle size={20} />
          ) : (
            <FaUserCircle size={20} />
          )}
          <div className="hidden md:block text-sm"> {session?.user.name}</div>
          <div className="sm:hidden">
            <AiOutlineMenu size={20} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className="
          absolute z-50 rounded-xl shadow-md w-[90vw] sm:w-[70vw] md:w-[50vw] lg:w-[30vw] 
          bg-white overflow-hidden right-0 top-12 text-sm
        "
        >
          <div className="flex flex-col cursor-pointer">
            {menuItems.map((item, index) => (
              <div key={index} className={item.style || ""}>
                {item.label === "Logout" && (
                  <hr className="border-t border-black" />
                )}
                <MenuItem
                  label={item.label}
                  onClick={() =>
                    item.onClick
                      ? handleMenuItemClick(item.onClick)
                      : handleMenuItemClick(() =>
                          router.push(
                            createUrlWithParams(item.path, item.data || {}),
                          ),
                        )
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;

// "use client";

// import { AiOutlineMenu } from "react-icons/ai";
// import { FaUserCircle } from "react-icons/fa";
// import { BiUserCircle } from "react-icons/bi";
// import { signOut, useSession } from "next-auth/react";
// import { useRouter } from "next/navigation";

// import useLoginModal from "@/app/hooks/useLoginModal";
// import useRegisterModal from "@/app/hooks/useRegisterModal";
// import { SimplifiedUser } from "@/app/types";
// import MenuItem from "./MenuItem";
// import { useEffect, useState } from "react";
// import { useUserStore } from "@/app/hooks/useUserStore";

// interface UserMenuProps {
//   currentUser?: SimplifiedUser | null;
//   isOpen: boolean;
//   toggleOpen: () => void;
// }

// const UserMenu: React.FC<UserMenuProps> = ({
//   currentUser,
//   isOpen,
//   toggleOpen,
// }) => {
//   const router = useRouter();
//   const loginModal = useLoginModal();
//   const registerModal = useRegisterModal();
//   // const { data: session } = useSession();
//   // const { currentUser, setCurrentUser, updateCurrentUser } = useUserStore();

//   // useEffect(() => {
//   //   if (session?.user) {
//   //     // Convertir la sesión a SimplifiedUser
//   //     const simplifiedUser = {
//   //       id: session.user.id,
//   //       name: session.user.name || "",
//   //       email: session.user.email || "",
//   //       image: session.user.image,
//   //       role: session.user.role || "USER",
//   //       userType: session.user.userType || "Diver",
//   //       isActive: true,
//   //       emailVerified: null,
//   //       diveCenter: null,
//   //       diver: null,
//   //     };

//   //     // Si no hay currentUser o ha cambiado, actualiza
//   //     if (!currentUser || currentUser.id !== simplifiedUser.id) {
//   //       setCurrentUser(simplifiedUser);
//   //     } else {
//   //       // Si ya existe, actualiza solo los campos que han cambiado
//   //       updateCurrentUser(simplifiedUser);
//   //     }
//   //   }
//   // }, [session?.user, currentUser, setCurrentUser, updateCurrentUser]);

//   // const handleSignOut = () => {
//   //   const callbackUrl = process.env.NEXTAUTH_URL;
//   //   signOut({ callbackUrl });
//   //   setCurrentUser(null); // Limpiar el usuario global al hacer logout
//   // };


//   const { data: session, status } = useSession();
//   const [localUserName, setLocalUserName] = useState(session?.user?.name);
//   const [localUserType, setLocalUserType] = useState(session?.user?.userType);

//   useEffect(() => {

//   console.log('data sessiondd', session);
//     // Solo actualiza si el nombre de la sesión es diferente
//     if (session?.user?.name && session.user.name !== localUserName) {
//       setLocalUserName(session.user.name);
//       // Opcional: router.refresh() si realmente necesitas un refresco completo
//     }
//     if (session?.user?.userType && session.user.userType !== localUserType) {
//       setLocalUserType(session.user.userType);
//       // Opcional: router.refresh() si realmente necesitas un refresco completo
//     }
//   }, [session?.user.name]);

  
//   const handleSignOut = () => {
//     const callbackUrl = process.env.NEXTAUTH_URL;
//     signOut({ callbackUrl });
//   };

//   const handleMenuItemClick = (action: () => void) => {
//     action();
//     toggleOpen();
//   };

//   const userData = {
//     type: session?.user.userType || currentUser?.userType,
//   };

//   const createUrlWithParams = (path: string, data: object) =>
//     `${path}?userData=${encodeURIComponent(JSON.stringify(data))}`;

//   const menuItems = currentUser
//     ? [
//         ...(currentUser.userType === "Diver"
//           ? [
//               {
//                 label: "Edit profile",
//                 path: `/editprofile/${currentUser.id}`,
//                 data: userData,
//               },
//               {
//                 label: "My reviews",
//                 path: `/myreviews/${currentUser.id}`,
//                 data: userData,
//               },
//               { label: "My favorites", path: "/favorites", data: userData },
//               // { label: "All job listings", path: "/job-listings" },
//               {
//                 label: "Write a review",
//                 path: "/write-review",
//                 style: "bg-atoll-500",
//               },
//               ...(currentUser.role === "ADMIN"
//                 ? [
//                     { label: "Claim center", path: "/adm/claim-center" },
//                     {
//                       label: "Centers management",
//                       path: "/adm/center-management",
//                     },
//                     { label: "Approval centers", path: "/adm/approval-center" },
//                   ]
//                 : []),
//             ]
//           : []),

//         ...(currentUser.userType === "Center"
//           ? [
//               {
//                 label: "Edit profile",
//                 path: `/editprofile/${currentUser.id}`,
//                 data: userData,
//               },
//               {
//                 label: "My profile",
//                 path: `/center/${currentUser.diveCenter?.id}`,
//                 data: userData,
//               },
//               { label: "My job listings", path: "/my-job-listings" },
//               {
//                 label: "List your jobs",
//                 path: "/register-job-listing",
//                 style: "bg-atoll-500",
//               },
//             ]
//           : []),

//         { label: "Logout", onClick: handleSignOut },
//       ]
//     : [
//         { label: "Login", onClick: loginModal.onOpen },
//         { label: "Sign up", onClick: registerModal.onOpen },
//       ];

//   return (
//     <div className="relative">
//       <div className="flex flex-row items-center gap-3">
//         <div
//           onClick={toggleOpen}
//           className="
//             p-2 md:py-2 md:px-3 border-[1px] 
//             border-neutral-200 flex flex-row items-center 
//             gap-3 rounded-lg cursor-pointer 
//             hover:shadow-md transition
//           "
//         >
//           {!session ? (
//             <BiUserCircle size={20} />
//           ) : (
//             <FaUserCircle size={20} />
//           )}
//           <div className="hidden md:block text-sm">{localUserName || session?.user.name || currentUser?.name}</div>
//           <div className="sm:hidden">
//             <AiOutlineMenu size={20} />
//           </div>
//         </div>
//       </div>
//       {isOpen && (
//         <div
//           className="
//           absolute z-50 rounded-xl shadow-md w-[90vw] sm:w-[70vw] md:w-[50vw] lg:w-[30vw] 
//           bg-white overflow-hidden right-0 top-12 text-sm
//         "
//         >
//           <div className="flex flex-col cursor-pointer">
//             {menuItems.map((item, index) => (
//               <div key={index} className={item.style || ""}>
//                 {item.label === "Logout" && (
//                   <hr className="border-t border-black" />
//                 )}
//                 <MenuItem
//                   label={item.label}
//                   onClick={() =>
//                     item.onClick
//                       ? handleMenuItemClick(item.onClick)
//                       : handleMenuItemClick(() =>
//                           router.push(
//                             createUrlWithParams(item.path, item.data || {}),
//                           ),
//                         )
//                   }
//                 />
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserMenu;
