"use client";

import React, { useState, lazy, Suspense } from "react";
import { FiSearch } from "react-icons/fi";
import { SafeSuggestionsDiveCenter } from "@/app/types";

const SearchCenters = lazy(() => import("./SearchCenters"));
const SearchListings = lazy(() => import("./SearchListings"));

interface SearchProps {
  centers?: SafeSuggestionsDiveCenter[];
  isOpen: boolean;
  toggleOpen: () => void;
}

const SearchToggle: React.FC<SearchProps> = ({ isOpen, toggleOpen }) => {
  const [activeSearch, setActiveSearch] = useState<"centers" | "jobs">("centers");

  const modalHeight = "calc(80vh)";
  const modalWidth = "min(100%, 640px)";

  return (
    <div className="flex justify-end items-center w-full">
      <div className="flex justify-end w-[23px] h-[23px] mr-2">
        <FiSearch
          size={20}
          className="cursor-pointer text-gray-600 hover:text-gray-800 transition-colors duration-200"
          onClick={toggleOpen}
          role="button"
          aria-label="Search toggle"
        />
      </div>

      {isOpen && (
        <div
          className="fixed inset-x-0 top-14 mx-auto bg-white shadow-lg z-50 overflow-hidden"
          style={{
            maxHeight: modalHeight,
            width: modalWidth,
            transform: "translate(-50%, 0)",
            left: "50%",
          }}
        >
          <div className="flex border-b border-gray-300">
            <SearchTab
              active={activeSearch === "centers"}
              onClick={() => setActiveSearch("centers")}
              label="Centers/Liveaboards"
            />
            <SearchTab
              active={activeSearch === "jobs"}
              onClick={() => setActiveSearch("jobs")}
              label="Job Listings"
            />
          </div>

          <div
            className="p-4 overflow-hidden"
            style={{ maxHeight: `calc(${modalHeight} - 48px)` }}
          >
            <Suspense
              fallback={
                <div className="w-full h-32 flex items-center justify-center">
                  Loading...
                </div>
              }
            >
              {activeSearch === "centers" ? (
                <SearchCenters toggleOpen={toggleOpen} />
              ) : (
                <SearchListings
                  toggleOpen={toggleOpen}
                />
              )}
            </Suspense>
          </div>
        </div>
      )}
    </div>
  );
};

const SearchTab: React.FC<{
  active: boolean;
  onClick: () => void;
  label: string;
}> = ({ active, onClick, label }) => (
  <button
    className={`flex-1 text-center py-2 cursor-pointer ${active
        ? "bg-gray-800 text-white border-b-2 border-atoll-600"
        : "text-gray-700"
      }`}
    onClick={onClick}
    aria-pressed={active}
  >
    {label}
  </button>
);

export default SearchToggle;
