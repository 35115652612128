export type CurrencyOption = {
  label: string;
  code: string;
  symbol: string;
  value: string;
};

const currencyOptions: CurrencyOption[] = [
  { label: "Euro", code: "EUR", symbol: "€", value: "eur" },
  // { label: 'Argentine Peso', code: 'ARS', symbol: '$', value: 'ars' },
  { label: "Australian Dollar", code: "AUD", symbol: "$", value: "aud" },
  // { label: 'Azerbaijani Manat', code: 'AZN', symbol: '₼', value: 'azn' },
  // { label: 'Bahraini Dinar', code: 'BHD', symbol: '.د.ب', value: 'bhd' },
  // { label: 'Brazilian Real', code: 'BRL', symbol: 'R$', value: 'brl' },
  { label: "Bulgarian Lev", code: "BGN", symbol: "лв", value: "bgn" },
  { label: "Canadian Dollar", code: "CAD", symbol: "$", value: "cad" },
  // { label: 'Chilean Peso', code: 'CLP', symbol: '$', value: 'clp' },
  { label: "Chinese Yuan", code: "CNY", symbol: "¥", value: "cny" },
  // { label: 'Colombian Peso', code: 'COP', symbol: '$', value: 'cop' },
  { label: "Czech Koruna", code: "CZK", symbol: "Kč", value: "czk" },
  { label: "Danish Krone", code: "DKK", symbol: "kr", value: "dkk" },
  // { label: 'Egyptian Pound', code: 'EGP', symbol: '£', value: 'egp' },
  // { label: 'Fijian Dollar', code: 'FJD', symbol: 'FJ$', value: 'fjd' },
  { label: "British Pound Sterling", code: "GBP", symbol: "£", value: "gbp" },
  // { label: 'Georgian Lari', code: 'GEL', symbol: '₾', value: 'gel' },
  // { label: 'Hong Kong Dollar', code: 'HKD', symbol: 'HK$', value: 'hkd' },
  { label: "Hungarian Forint", code: "HUF", symbol: "Ft", value: "huf" },
  { label: "Icelandic Króna", code: "ISK", symbol: "kr", value: "isk" },
  { label: "Indian Rupee", code: "INR", symbol: "₹", value: "inr" },
  { label: "Indonesian Rupiah", code: "IDR", symbol: "Rp", value: "idr" },
  { label: "Israeli New Shekel", code: "ILS", symbol: "₪", value: "ils" },
  { label: "Japanese Yen", code: "JPY", symbol: "¥", value: "jpy" },
  // { label: 'Jordanian Dinar', code: 'JOD', symbol: 'JD', value: 'jod' },
  // { label: 'South Korean Won', code: 'KRW', symbol: '₩', value: 'krw' },
  // { label: 'Kuwaiti Dinar', code: 'KWD', symbol: 'KD', value: 'kwd' },
  // { label: 'Kazakhstani Tenge', code: 'KZT', symbol: '₸', value: 'kzt' },
  // { label: 'Moroccan Dirham', code: 'MAD', symbol: 'د.م.', value: 'mad' },
  // { label: 'Moldovan Leu', code: 'MDL', symbol: 'MDL', value: 'mdl' },
  { label: "Mexican Peso", code: "MXN", symbol: "$", value: "mxn" },
  { label: "Malaysian Ringgit", code: "MYR", symbol: "RM", value: "myr" },
  // { label: 'Namibian Dollar', code: 'NAD', symbol: 'N$', value: 'nad' },
  { label: "Norwegian Krone", code: "NOK", symbol: "kr", value: "nok" },
  { label: "New Zealand Dollar", code: "NZD", symbol: "$", value: "nzd" },
  // { label: 'Omani Rial', code: 'OMR', symbol: '﷼', value: 'omr' },
  { label: "Philippine peso", code: "PHP", symbol: "₱", value: "php" },
  { label: "Polish Złoty", code: "PLN", symbol: "zł", value: "pln" },
  // { label: 'Qatari Riyal', code: 'QAR', symbol: '﷼', value: 'qar' },
  { label: "Romanian Leu", code: "RON", symbol: "lei", value: "ron" },
  { label: "Russian Ruble", code: "RUB", symbol: "₽", value: "rub" },
  // { label: 'Saudi Arabian Riyal', code: 'SAR', symbol: '﷼', value: 'sar' },
  { label: "Singapore Dollar", code: "SGD", symbol: "S$", value: "sgd" },
  { label: "South African Rand", code: "ZAR", symbol: "R", value: "zar" },
  { label: "South Korean Won", code: "KRW", symbol: "₩", value: "krw" },
  { label: "Swedish Krona", code: "SEK", symbol: "kr", value: "sek" },
  { label: "Swiss Franc", code: "CHF", symbol: "CHF", value: "chf" },
  { label: "Thai Baht", code: "THB", symbol: "฿", value: "thb" },
  { label: "Turkish Lira", code: "TRY", symbol: "₺", value: "try" },
  // { label: 'New Taiwan Dollar', code: 'TWD', symbol: 'NT$', value: 'twd' },
  // { label: 'Ukrainian Hryvnia', code: 'UAH', symbol: '₴', value: 'uah' },
  { label: "United States Dollar", code: "USD", symbol: "$", value: "usd" },
];

const useCurrencies = () => {
  const getAllCurrencies = () => currencyOptions;

  const getBySymbol = (symbol: string) => {
    return currencyOptions.find((item) => item.symbol === symbol);
  };

  const getByCode = (code: string) => {
    return currencyOptions.find((item) => item.code === code);
  };

  return {
    getAllCurrencies,
    getBySymbol,
    getByCode,
  };
};

export default useCurrencies;
