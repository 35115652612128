"use client";

import Link from "next/link";

interface FooterLink {
  href: string;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

interface FooterProps {
  title: string;
  links: FooterLink[];
}

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white border-t border-gray-300 sm:py-8 py-6 px-6 text-xs">
      <div className="container mx-auto grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 sm:gap-4 gap-2 md:text-sm text-xs text-gray-600">
        <FooterSection
          title="Scuba Reputation"
          links={[
            { href: "/about", text: "About us" },
            { href: "/contact-us", text: "Contact us" },
          ]}
        />
        <FooterSection
          title="Dive Center"
          links={[
            { href: "/register-my-center", text: "Create a free account" },
            { href: "/claim-profile", text: "Claim your profile" },
          ]}
        />
        <FooterSection
          title="Dive Professional"
          links={[
            { href: "/register-dive-center", text: "Register a dive center" },
            { href: "/write-review", text: "Write a review" },
          ]}
        />
        <FooterSection
          title="General Info"
          links={[
            { href: "/faq", text: "FAQs" },
            { href: "/privacy-policy", text: "Privacy Policy" },
          ]}
        />
        <FooterSection
          title="Legal Info"
          links={[
            { href: "/terms-of-service", text: "Terms of Service" },
            { href: "/user-data-deletion", text: "User Data Deletion" },
          ]}
        />
        <FooterSection
          title="Work with us"
          links={[{ href: "/contact-us", text: "Scuba brands" }]}
        />
      </div>
      <div className="text-center sm:mt-8 mt-6 text-gray-500">
        &copy; {currentYear} Scuba Reputation. All rights reserved.
      </div>
    </footer>
  );
};

const FooterSection: React.FC<FooterProps> = ({ title, links }) => (
  <div>
    <h3 className="font-semibold sm:mb-3 mb-2 text-base">{title}</h3>
    <ul>
      {links.map((link, index) => (
        <li key={index} className="mb-2">
          {link.onClick ? (
            <a
              href={link.href}
              onClick={link.onClick}
              className="text-sm hover:underline"
            >
              {link.text}
            </a>
          ) : (
            <Link href={link.href} className="text-sm hover:underline">
              {link.text}
            </Link>
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default Footer;