import(/* webpackMode: "eager" */ "/app/app/components/ClientOnly.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/CookieBanner.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/modals/LoginModal.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/modals/RegisterModal.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/modals/ReviewReminderModal.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/navbar/Navbar.tsx");
import(/* webpackMode: "eager" */ "/app/app/globals.css");
import(/* webpackMode: "eager" */ "/app/app/providers/ToasterProvider.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"raleway\"}")