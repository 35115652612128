"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";

const Logo = () => {
  const router = useRouter();

  return (
    // <a href="/" rel="noopener noreferrer">
    <Image
      onClick={() => router.push("/")}
      className="block cursor-pointer"
      // className="cursor-pointer max-w-[9rem] sm:max-w-[12rem]"
      src="/images/logo-scuba.png"
      height={150}
      width={150}
      alt="Logo"
      priority
    />
    // </a>
  );
};

export default Logo;
