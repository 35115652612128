"use client";

import React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import useCurrencies from "@/app/hooks/useCurrencies";
import { ChevronDown } from "lucide-react";

export type CurrencyOption = {
  label: string;
  code: string;
  symbol: string;
  value: string;
};

interface CurrencySelectProps {
  value?: CurrencyOption | null;
  onChange: (value: CurrencyOption | null) => void;
  className?: string;
  error?: boolean;
  id?: string;
}

const CurrencySelector: React.FC<CurrencySelectProps> = ({
  value,
  onChange,
  className,
  error,
  id,
}) => {
  const { getAllCurrencies } = useCurrencies();
  const [currencies, setCurrencies] = React.useState<CurrencyOption[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const allCurrencies = await getAllCurrencies();
        setCurrencies(allCurrencies);
      } catch (error) {
        console.error("Failed to load currencies.");
      } finally {
        setLoading(false);
      }
    };

    fetchCurrencies();
  }, [getAllCurrencies]);

  const handleValueChange = (selectedValue: string) => {
    const selectedCurrency =
      currencies.find((currency) => currency.value === selectedValue) || null;
    onChange(selectedCurrency);
  };

  return (
    <div className={className}>
      {loading ? (
        <div className="text-center">Loading currencies...</div>
      ) : (
        <SelectPrimitive.Root
          onValueChange={handleValueChange}
          value={value?.value || ""}
        >
          <SelectPrimitive.Trigger
            className={`flex w-full items-center justify-between rounded-lg border ${error ? "border-red-500" : "border-gray-300"} bg-white px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-atoll-500`}
          >
            <SelectPrimitive.Value placeholder="Currency">
              {value ? (
                <div className="flex flex-col">
                  <span className="text-xs">
                    {value.code} - {value.symbol}
                  </span>
                </div>
              ) : (
                "Currency"
              )}
            </SelectPrimitive.Value>
            <SelectPrimitive.Icon asChild>
              <ChevronDown className="h-4 w-4 opacity-50" />
            </SelectPrimitive.Icon>
          </SelectPrimitive.Trigger>
          <SelectPrimitive.Content
            position="popper"
            sideOffset={4}
            className="relative z-50 min-w-[200px] w-[--radix-select-trigger-width] max-h-[300px] overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg"
            align="start"
          >
            <SelectPrimitive.ScrollUpButton className="absolute top-0 left-0 w-full h-5" />
            <SelectPrimitive.Viewport className="p-1">
              {currencies.map((currency) => (
                <SelectPrimitive.Item
                  key={currency.value}
                  value={currency.value}
                  className="relative flex items-center px-6 py-2 text-sm text-gray-900 rounded-md cursor-default select-none hover:bg-red-100 focus:bg-red-100 focus:outline-none"
                >
                  <div className="flex flex-col text-justify text-xs">
                    <div>{currency.label}</div>
                    <div>
                      {currency.code} -{" "}
                      <span className="text-neutral-500 ml-1">
                        {currency.symbol}
                      </span>
                    </div>
                  </div>
                </SelectPrimitive.Item>
              ))}
            </SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className="absolute bottom-0 left-0 w-full h-5" />
          </SelectPrimitive.Content>
        </SelectPrimitive.Root>
      )}
    </div>
  );
};

export default CurrencySelector;
