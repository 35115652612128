import { create } from "zustand";

interface ReviewReminderModalStore {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const useReviewReminderModal = create<ReviewReminderModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}));

export default useReviewReminderModal;
