"use client";

import { signIn } from "next-auth/react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaEye, FaEyeSlash } from "react-icons/fa";
import { useRouter, useSearchParams } from "next/navigation";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import useRegisterModal from "@/app/hooks/useRegisterModal";
import useLoginModal from "@/app/hooks/useLoginModal";
import { LoginSchema } from "@/app/schemas";
import { login } from "@/app/actions/login";

import Modal from "./Modal";
import Heading from "../Heading";
import Button from "../Button";
import { ButtonS } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { FormError } from "@/app/components/FormError";
import { FormSuccess } from "@/app/components/FormSucess";

interface LoginSelectProps {
  currentUser?: any;
}

const LoginModal: React.FC<LoginSelectProps> = () => {
  const router = useRouter();
  const loginModal = useLoginModal();
  const registerModal = useRegisterModal();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState<{ error?: string; success?: string }>(
    {},
  );
  const searchParams = useSearchParams();
  const urlError =
    searchParams?.get("error") === "OAuthAccountNotLinked"
      ? "Email already in use with a different provider!"
      : "";


  const form = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof LoginSchema>) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    setMessage({});

    try {
      const data = await login(values);

      if (data.error) {
        setMessage({ error: data.error });
        return;
      }

      if (data.success === "Confirmation email sent!") {
        form.reset();
        setMessage({ success: data.success });
        return;
      }

      const result = await signIn("credentials", {
        ...values,
        redirect: false,
      });

      if (result?.error) {
        setMessage({ error: "Invalid credentials" });
        return;
      }

      toast.success("Logged in!");
      loginModal.onClose();
      // router.refresh();
      // router.push("/");
    } catch (error) {
      setMessage({ error: "Something went wrong" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSocialLogin = async (provider: "google" | "facebook") => {
    if (isSubmitting) return;

    try {
      await signIn(provider, { callbackUrl: "/" });
    } catch (error) {
      toast.error(`Something went wrong with ${provider} login`);
    }
  };

  useEffect(() => {
    if (urlError) {
      toast.error(urlError);
    }
  }, [urlError]);

  return (
    <Modal
      disabled={isSubmitting}
      isOpen={loginModal.isOpen}
      title="Login"
      onClose={loginModal.onClose}
      body={
        <div className="flex flex-col gap-4">
          <Heading title="Welcome back" subtitle="Login to your account!" />
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <div className="space-y-4">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          disabled={isSubmitting}
                          placeholder="example@example.com"
                          type="email"
                        />
                      </FormControl>
                      <FormMessage className="text-red-500 text-xs" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <div className="flex w-full items-center">
                        <FormControl>
                          <Input
                            {...field}
                            disabled={isSubmitting}
                            placeholder="******"
                            type={showPassword ? "text" : "password"}
                          />
                        </FormControl>
                        <ButtonS
                          type="button"
                          onClick={() => setShowPassword((prev) => !prev)}
                          className="text-neutral-600 border-[1px] rounded-lg border-neutral-200"
                        >
                          {showPassword ? (
                            <FaEyeSlash size={16} />
                          ) : (
                            <FaEye size={16} />
                          )}
                        </ButtonS>
                      </div>
                      <FormMessage className="text-red-500 text-xs" />
                    </FormItem>
                  )}
                />
              </div>
              <FormError message={message.error || urlError} />
              <FormSuccess message={message.success} />
              <ButtonS
                type="submit"
                disabled={isSubmitting}
                className="h-10 disabled:opacity-70 disabled:cursor-not-allowed rounded-lg hover:opacity-80 transition w-full bg-atoll-600 font-semibold text-white text-md py-3"
              >
                Login
              </ButtonS>
            </form>
          </Form>
          <div className="flex justify-end mt-2 font-bold">
            <span
              onClick={() => {
                loginModal.onClose();
                router.push("/auth/forgotpassword");
              }}
              className="text-atoll-700 cursor-pointer hover:underline"
            >
              Forgot password?
            </span>
          </div>
        </div>
      }
      footer={
        <div className="flex flex-col gap-2">
          <hr />
          <Button
            outline
            label="Continue with Google"
            icon={FcGoogle}
            onClick={() => handleSocialLogin("google")}
          />
          {/* <Button
            outline
            label="Continue with Facebook"
            icon={FaFacebook}
            className="facebook-button"
            onClick={() => handleSocialLogin("facebook")}
          /> */}
          <div className="text-neutral-500 text-center mt-4 text-xs">
            <p>
              First time here?
              <span
                onClick={() => {
                  loginModal.onClose();
                  registerModal.onOpen();
                }}
                className="text-neutral-800 cursor-pointer hover:underline"
              >
                {" "}
                Register as a DIVE PRO
              </span>
            </p>
            <p>
              <span
                onClick={() => {
                  loginModal.onClose();
                  router.push("/register-my-center");
                }}
                className="text-atoll-700 cursor-pointer hover:underline"
              >
                {" "}
                Create an account as a DIVE CENTER
              </span>
            </p>
          </div>
        </div>
      }
    />
  );
};

export default LoginModal;