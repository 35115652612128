import { BiError } from "react-icons/bi";

interface FormErrorProps {
  message?: string;
}

export const FormError = ({ message }: FormErrorProps) => {
  if (!message) return null;

  return (
    <div className="bg-red-500/15 p-2 rounded-md flex items-center gap-x1 text-sm text-red-500">
      <BiError className="h-4 w-4 mr-2" />
      <p>{message}</p>
    </div>
  );
};
