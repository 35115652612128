"use client";

import { useCallback, useEffect } from "react";
import Modal from "./Modal";
import useReviewReminderModal from "@/app/hooks/useReviewReminderModal";
import useLoginModal from "@/app/hooks/useLoginModal";
import { useRouter } from "next/navigation";

const REMINDER_INTERVAL = 8 * 60 * 60 * 1000;

const ReviewReminderModal = ({ currentUser }: { currentUser: any }) => {
  const reviewReminderModal = useReviewReminderModal();
  const loginModal = useLoginModal();
  const router = useRouter();

  useEffect(() => {
    if (!currentUser) return;

    if (currentUser.role === "ADMIN") {
      return;
    }

    if (currentUser.userType === "Center") {
      return;
    }

    const isWritingReview = sessionStorage.getItem("isWritingReview");
    if (isWritingReview) {
      return;
    }

    const hasSeenReminderSession = sessionStorage.getItem(
      "hasSeenReviewReminder",
    );
    const lastReminderTime = localStorage.getItem("lastReminderTime");
    const currentTime = new Date().getTime();

    if (!hasSeenReminderSession) {
      const timer = setTimeout(() => {
        reviewReminderModal.onOpen();
        sessionStorage.setItem("hasSeenReviewReminder", "true");
      }, 100000);
      // }, 500);

      return () => clearTimeout(timer);
    }

    if (
      lastReminderTime &&
      currentTime - parseInt(lastReminderTime) >= REMINDER_INTERVAL
    ) {
      reviewReminderModal.onOpen();
      localStorage.setItem("lastReminderTime", currentTime.toString());
    }
  }, [currentUser, reviewReminderModal]);

  const handleWriteReview = useCallback(() => {
    if (!currentUser) {
      loginModal.onOpen();
      reviewReminderModal.onClose();
    } else {
      router.push("/write-review");
    }
  }, [currentUser, loginModal, reviewReminderModal, router]);

  return (
    <Modal
      isOpen={reviewReminderModal.isOpen}
      onClose={reviewReminderModal.onClose}
      title="Write a Review!"
      body={
        <p className="text-justify">
          Your opinion matters! Help us and the community by sharing your
          review. Your feedback helps others make informed decisions.
        </p>
      }
      actionLabel="Write Review"
      onSubmit={handleWriteReview}
    />
  );
};

export default ReviewReminderModal;
