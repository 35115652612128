"use client";

import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  SafeListing,
  SimplifiedUser,
} from "@/app/types";
import Container from "../Container";
import Logo from "./Logo";
import UserMenu from "./UserMenu";
import SearchToggle from "./search/SearchToggle";
import CurrencyMenu from "./Currency";
import { SessionProvider, useSession } from "next-auth/react";

interface NavbarProps {
  simplifiedUser?: SimplifiedUser | null;
  hasReviews?: boolean;
  session?: any;
}

const Navbar: React.FC<NavbarProps> = ({
  simplifiedUser,
  hasReviews,
  session
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isCurrencyMenuOpen, setIsCurrencyMenuOpen] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);

  const toggleSearch = useCallback(() => {
    setIsSearchOpen((prev) => !prev);
    setIsUserMenuOpen(false);
    setIsCurrencyMenuOpen(false);
  }, []);

  const toggleUserMenu = useCallback(() => {
    setIsUserMenuOpen((prev) => !prev);
    setIsSearchOpen(false);
    setIsCurrencyMenuOpen(false);
  }, []);

  const toggleCurrencyMenu = useCallback(() => {
    setIsCurrencyMenuOpen((prev) => !prev);
    setIsSearchOpen(false);
    setIsUserMenuOpen(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        setIsSearchOpen(false);
        setIsUserMenuOpen(false);
        setIsCurrencyMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="fixed w-full bg-white z-10 shadow-sm" ref={navbarRef}>
      <div className="pt-3 pb-2 border-b-[2px] border-gray-300">
        <Container>
          <div className="flex flex-row items-center justify-between gap-3 md:gap-0 max-w-[1400px] w-full mx-auto">
            <Logo />
            <SearchToggle
              isOpen={isSearchOpen}
              toggleOpen={toggleSearch}
            />
            <CurrencyMenu
              currentUser={simplifiedUser}
              hasReviews={hasReviews}
              isOpen={isCurrencyMenuOpen}
              toggleOpen={toggleCurrencyMenu}
            />
            <SessionProvider session={session}>
            <UserMenu
              currentUser={simplifiedUser}
              isOpen={isUserMenuOpen}
              toggleOpen={toggleUserMenu}
            />
            </SessionProvider>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default React.memo(Navbar);
